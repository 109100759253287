/*CABECALHO*/



.cabecalho {
  min-height: 15vh;
  background-color: #00995d;
}

.cabecalho_faixa {
  height: 4vh;
  background-color: #00401a;
}

.logo_unimed {
  display: flex;
  min-height: 15vh;
  align-items: center;
  padding-left: 15px;
}

/*RODAPE*/

.rodape {
  display: flex;
  min-height: 4vh;
  width: 100%;
  bottom: 0;
  background-color: #00401a;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
}

.text {
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width: 601px) {
  .text {
    font-size: 10px;
  }
}

.image_logo {
  width: 50%;
}

@media screen and (min-width: 601px) {
  .image_logo {
    width: 10%;
  }
}

@media screen and (max-width: 601px) {
  .image_logo {
    width: 35%;
  }
}

/*ESTRUTURA*/

.card_text_click {
  padding: 15px;
}

.card_text_click:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  padding: 30px;
  max-width: 100%;
}

.card:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.image_card {
  max-width: 100%;
}

.image_card_base {
  max-width: 100%;
  background-image: url("./images/BASE.png");
  background-position: center;
  border-radius: 20px;
}

.div_row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

.card_text {
  padding: 20px;
}

.card_text2 {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_text_button {
  max-width: 500px;
  background-color: #a7d147;
  padding: 15px;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  color: #00401a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: "UnimedSlab-Black";
  cursor: pointer;
  letter-spacing: 1.5px;
  line-height: 20px;
}

.card_text_button:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.cards_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.estrutura_cabecalho {
  height: 19vh;
}

.estrutura_conteudo {
  flex-direction: column;
  display: flex;
  min-height: 77vh;
  align-items: center;
  justify-content: center;
}

.estrutura_rodape {
  min-height: 4vh;
}

.titulo_tipo_plano {
  color: #188c57;
  display: flex;
  margin-top: 3%;
  font-size: 20px;
  margin-bottom: 3%;
  font-family: "UnimedSlab-Black";
  align-self: center;
  text-align: center;
}

@media screen and (min-width: 601px) {
  .titulo_tipo_plano {
    font-size: 30px;
    text-align: center;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.carteira {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

@media screen and (min-width: 601px) {
  .carteira {
    height: 300px;
    width: 400px;
    margin: 35px;
  }
}

.tipo_plano {
  color: #188c57;
  font-family: "UnimedSlab-Black";
  padding-top: 30px;
  text-align: center;
  min-height: 70px;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 601px) {
  .tipo_plano {
    font-size: 25px;
  }
}

@media screen and (max-width: 300px) {
  .tipo_plano {
    font-size: 20px;
  }
}
